<template>
    <main id="js-page-content" class="page-content" role="main">
        <div class="container-fluid">
            <div class="form-card">
                <div class="row justify-center">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1">
                            <template v-slot:header>
                                <h2>
                                    <span class="icon-stack fs-xxl mr-2">
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-building icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    {{ title }}
                                </h2>
                            </template>
                            <div class="panel-container collapse show" style="">
                                <div class="panel-content">
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="webhook-name"
                                                    >{{
                                                        $t('compagnies.name')
                                                    }}</label
                                                >
                                                <input
                                                    id="webhook-name"
                                                    v-model="webhook.name"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="webhook-url"
                                                    >URL</label
                                                >
                                                <input
                                                    id="webhook-url"
                                                    v-model="webhook.url"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="webhook-signature_secret"
                                                    >{{
                                                        $t('compagnies.secret')
                                                    }}</label
                                                >
                                                <input
                                                    id="webhook-signature_secret"
                                                    v-model="
                                                        webhook.signature_secret
                                                    "
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div v-if="!saved" class="d-flex justify-center">
                            <button
                                :disabled="canCreate()"
                                class="btn btn-primary"
                                href="#"
                                @click.prevent="doCreate"
                            >
                                {{ submitButtonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Panel from '@/components/UI/Panel'

export default {
    name: 'UserBuilder',
    components: {
        Panel
    },
    data() {
        let webhookId = this.$route.params.webhookId
        const title = webhookId
            ? this.$t('compagnies.webhook.edit')
            : this.$t('compagnies.webhook.create')
        const submitButtonText = webhookId
            ? this.$t('compagnies.webhook.edit_webhook')
            : this.$t('compagnies.webhook.create_webhook')
        return {
            loading: false,
            saved: false,
            title,
            submitButtonText
        }
    },
    computed: {
        ...mapState('company', {
            webhook: (state) => state.newWebhook
        })
    },
    created() {
        let webhookId = this.$route.params.webhookId
        if (webhookId) {
            this.loading = true
            this.editWebhook(webhookId)
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    methods: {
        ...mapActions('company', {
            createWebhook: 'createWebhook',
            updateWebhook: 'updateWebhook',
            editWebhook: 'editWebhook'
        }),
        doCreate: async function () {
            this.loading = true

            let webhookId = this.$route.params.webhookId

            let request

            if (webhookId) {
                request = this.updateWebhook()
            } else {
                request = this.createWebhook()
            }

            request
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showSuccessMessage: function () {
            this.$store.dispatch('toast', {
                type: 'success',
                title: this.$t('success.title'),
                message: this.$t('succes.message')
            })
        },
        showErrorMessage: function (errorMessage) {
            this.$store.dispatch('toast', {
                type: 'error',
                title: this.$t('error.title'),
                message:
                    errorMessage && errorMessage.length > 0
                        ? errorMessage
                        : this.$t('error.validation.retry')
            })
        },
        canCreate: function () {
            return !!this.loading
        }
    },
    beforeDestroy() {
        this.$store.commit('company/setNewWebhook', {
            name: null,
            url: null,
            signature_secret: null
        })
    }
}
</script>

<style></style>
